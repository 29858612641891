<script setup lang="ts">
import {ref, defineAsyncComponent} from 'vue';
import useCpBem from '~/composables/useCpBem';

const Vue3Lottie = defineAsyncComponent(() => import('vue3-lottie').then((module) => module.Vue3Lottie));

const animationFiles = {
  /**
   * @deprecated featuredProduct animation is deprecated use CpFeaturedAnimation instead
   */
  featuredProduct: () => import('~/assets/animations/featuredProductsSection.json').then((module) => module.default),
  success: () => import('~/assets/animations/success.json').then((module) => module.default),
  loader: () => import('~/assets/animations/loader.json').then((module) => module.default),
};

const animations = {
  featuredProduct: {
    name: 'featuredProduct',
    height: '24px',
    width: '24px',
    loop: true,
  },
  success: {
    name: 'success',
    height: '64px',
    width: '64px',
    loop: false,
  },
  loader: {
    name: 'loader',
    height: '24px',
    width: '24px',
    loop: true,
  },
};

const props = defineProps<{
  name: keyof typeof animations
}>();
const animation = ref(animations[props.name]);
const animationData = ref<any>(null);
const {b} = useCpBem('cp-animation');

// Load only the required animation json file
async function loadAnimationData() {
  animationData.value = await animationFiles[props.name]();
}

loadAnimationData();
</script>

<template>
    <ClientOnly>
        <div v-if="animationData" :class="b">
            <Vue3Lottie v-if="animationData" :animation-data="animationData" :loop="animation.loop" />
        </div>
    </ClientOnly>
</template>

<style scoped lang="scss">
.cp-animation {
  height: v-bind("animation.height");
  width: v-bind("animation.width");
}
</style>
